require('./bootstrap');

require('alpinejs');


$(window).on("load resize scroll", function() {
    $(".glafka-landing").each(function() {
      var windowTop = $(window).scrollTop();
      var elementTop = $(this).offset().top;
      var topPosition = windowTop - elementTop;
      if (topPosition < 0 ) topPosition = 0;
        $(this)
          .find(".intro-image")
          .css({ 'background-position-y': topPosition/3 });
    });
});

// Slick - our partnters slider
require('slick-carousel');
$('.partner-slider').slick({
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3800,
    infinite: true,
    speed: 1000,
    centerMode: false,
    variableWidth: true,
    pauseOnHover: true
});


// resize navbar on scroll
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  // console.log(document.body.scrollTop);
  // console.log(document.documentElement.scrollTop);
  if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300 ) {
    // dole
    document.getElementById("main-nav").style.paddingTop = "5px";
    document.getElementById("main-nav").style.paddingBottom = "5px";
    document.getElementById("main-nav-logo").style.height = "50px";
    document.getElementById("main-nav-logo").classList.remove('notransition'); // Enable transitions
    document.getElementById("main-nav").classList.remove('notransition'); // Enable transitions
  } else {
    // hore - remove padding
    document.getElementById("main-nav").style.paddingTop = null;
    document.getElementById("main-nav").style.paddingBottom = null;
    document.getElementById("main-nav-logo").style.height = "75px";
    if (document.documentElement.scrollTop < 100){
      document.getElementById("main-nav-logo").classList.add('notransition'); // Disable transitions
      document.getElementById("main-nav").classList.add('notransition'); // Disable transitions
    }
  }
}
